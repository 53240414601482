import React, { useState, useEffect } from "react";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import EditTopic from "./EditTopic";
import { Edit } from "@mui/icons-material";

export default function TopicList() {
  const [topics, setTopics] = useState([]);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    const querySnapshot = await getDocs(collection(db, "topics"));
    const topicsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTopics(topicsArray);
  };

  const handleEditClick = (topicId) => {
    setSelectedTopicId(topicId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTopicId(null);
  };

  const handleSave = () => {
    fetchTopics(); // Refresh the topic list after editing
    handleCloseModal();
  };

  return (
    <>
      <Typography variant="h5">Topic List</Typography>
      <List>
        {topics.map((topic) => (
          <ListItem key={topic.id}>
            <ListItemText primary={topic.name} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEditClick(topic.id)}
              startIcon={<Edit />}
            >
              Edit
            </Button>
          </ListItem>
        ))}
      </List>

      {/* EditTopic Modal */}
      <EditTopic
        open={openModal}
        handleClose={handleCloseModal}
        topicId={selectedTopicId}
        onSave={handleSave} // Pass onSave to refresh the list
      />
    </>
  );
}
