import React, { useState } from "react";
import ResponsiveDrawer from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../services/encrypt";
export default function Dashboard() {
  const navigate = useNavigate();
  const [token, setToken] = useState(decrypt(sessionStorage.getItem("token")));

  if (token) {
    var jsonToken = JSON.parse(token);
    if (
      "username" in jsonToken &&
      "email" in jsonToken &&
      "password" in jsonToken &&
      "topics" in jsonToken
    ) {
      return <ResponsiveDrawer />;
    } else {
      navigate("/auth");
    }
  } else {
    navigate("/auth");
  }
}
