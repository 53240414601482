import React, { useState, useEffect } from "react";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import EditUser from "./EditUser";
import { Edit } from "@mui/icons-material";

export default function UserList() {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const usersArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(usersArray);
  };

  const handleEditClick = (userId) => {
    setSelectedUserId(userId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUserId(null);
  };

  const handleSave = () => {
    fetchUsers(); // Refresh the user list after editing
    handleCloseModal();
  };

  return (
    <>
      <Typography variant="h5">User List</Typography>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemText primary={user.name} secondary={user.email} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEditClick(user.id)}
              startIcon={<Edit />}
            >
              Edit
            </Button>
          </ListItem>
        ))}
      </List>

      {/* EditUser Modal */}
      <EditUser
        open={openModal}
        handleClose={handleCloseModal}
        userId={selectedUserId}
        onSave={handleSave} // Pass onSave to refresh the list
      />
    </>
  );
}
