import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Modal,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { db, storage } from "../../../config/firebase";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const modalStyle = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  height: "90%",
  [theme.breakpoints.up("md")]: {
    width: 600,
    height: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: 800,
    height: "auto",
  },
});

const loadingStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100px",
};

const progressModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const EditTopic = ({ open, handleClose, topicId, onSave }) => {
  const [formData, setFormData] = useState({ name: "", videos: [] });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [progressOpen, setProgressOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showUserAccessDialog, setShowUserAccessDialog] = useState(false);

  useEffect(() => {
    if (topicId) {
      fetchTopicData(topicId);
    }
  }, [topicId]);

  const fetchTopicData = async (id) => {
    setLoading(true);
    try {
      const docRef = doc(db, "topics", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setFormData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching topic data: ", error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleVideoTitleChange = (index, newTitle) => {
    const newVideos = [...formData.videos];
    newVideos[index].title = newTitle;
    setFormData({ ...formData, videos: newVideos });
  };

  const handleRemoveVideo = (index) => {
    const newVideos = formData.videos.filter((_, i) => i !== index);
    setFormData({ ...formData, videos: newVideos });
  };

  const handleAddVideo = (e) => {
    e.preventDefault();

    // Create a file input to allow user to select a video file
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const videoRef = ref(storage, `videos/${file.name}`);
      const uploadTask = uploadBytesResumable(videoRef, file);

      setProgressOpen(true); // Show uploading progress modal

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading video: ", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Add video to the formData after upload completes
          setFormData({
            ...formData,
            videos: [...formData.videos, { title: "", url: downloadURL }],
          });
          setProgressOpen(false); // Hide progress modal after upload completes
        }
      );
    };
    fileInput.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name) validationErrors.name = "Name is required";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const topicRef = doc(db, "topics", topicId);
        await updateDoc(topicRef, {
          name: formData.name,
          videos: formData.videos,
        });
        console.log("Topic successfully updated!");
        handleClose(); // Close modal on success
        onSave(); // Trigger list refresh
      } catch (error) {
        console.error("Error updating topic: ", error);
      }
    }
  };

  const handleDeleteTopic = async () => {
    setShowConfirmDelete(false);
    setProgressOpen(true);

    try {
      const userQuery = query(
        collection(db, "users"),
        where("topics", "array-contains", topicId)
      );
      const userDocs = await getDocs(userQuery);

      if (userDocs.empty) {
        await deleteDoc(doc(db, "topics", topicId));
        console.log("Topic successfully deleted!");
        handleClose();
        onSave();
      } else {
        setShowUserAccessDialog(true);
      }
    } catch (error) {
      console.error("Error deleting topic: ", error);
    } finally {
      setProgressOpen(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "1rem",
              }}
              item
              xs={12}
            >
              <Typography variant="h6">Edit Topic</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {loading ? (
              <Grid item xs={12} style={loadingStyle}>
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                {formData.videos.map((video, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      fullWidth
                      label={`Video ${index + 1} Title`}
                      value={video.title}
                      onChange={(e) =>
                        handleVideoTitleChange(index, e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button onClick={() => handleRemoveVideo(index)}>
                              <RemoveIcon />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddVideo}
                    startIcon={<AddIcon />}
                  >
                    Add Video
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowConfirmDelete(true)}
                    style={{ marginLeft: 8 }}
                  >
                    Delete Topic
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>

      <Dialog
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this topic? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
          <Button onClick={handleDeleteTopic} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showUserAccessDialog}
        onClose={() => setShowUserAccessDialog(false)}
      >
        <DialogTitle>Cannot Delete Topic</DialogTitle>
        <DialogContent>
          <Typography>
            This topic cannot be deleted because it is associated with one or
            more users.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUserAccessDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Modal open={progressOpen}>
        <Box sx={progressModalStyle}>
          <Typography variant="h6">Uploading...</Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2">{Math.round(uploadProgress)}%</Typography>
        </Box>
      </Modal>
    </>
  );
};

export default EditTopic;
