import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Modal,
  Box,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  InputLabel,
  FormControl,
  InputAdornment,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from "@firebase/firestore";
import { db } from "../../../config/firebase";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const EditUser = ({ open, handleClose, userId, onSave }) => {
  const initialFormData = {
    name: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    topics: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
      fetchTopics();
    }
  }, [userId]);

  const fetchTopics = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "topics"));
      const topicsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setTopics(topicsArray);
    } catch (error) {
      console.error("Error fetching topics: ", error);
    }
    setLoading(false);
  };

  const fetchUserData = async (id) => {
    setLoading(true);
    try {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setFormData({ ...docSnap.data(), password: "", confirmPassword: "" });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTopicsChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      topics: typeof value === "string" ? value.split(",") : value,
    });
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.username) validationErrors.username = "Username is required";
    if (formData.password !== formData.confirmPassword)
      validationErrors.confirmPassword = "Passwords do not match";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
          name: formData.name,
          email: formData.email,
          username: formData.username,
          topics: formData.topics,
          ...(formData.password && { password: formData.password }),
        });
        console.log("User successfully updated!");
        handleClose(); // Close modal after saving
        onSave(); // Call onSave to refresh the user list
      } catch (error) {
        console.error("Error updating user: ", error);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "users", userId));
      handleCloseDeleteModal();
      onSave(); // Refresh the user list after deletion
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const closeModalAndClearData = () => {
    handleClose();
    setFormData(initialFormData); // Clear form data
    setErrors({}); // Reset errors
  };

  return (
    <>
      <Modal open={open} onClose={closeModalAndClearData}>
        <Box sx={modalStyle}>
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={closeModalAndClearData}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" gutterBottom>
            Edit User
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Username"
                  fullWidth
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  error={!!errors.username}
                  helperText={errors.username}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm Password"
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleConfirmPasswordVisibility}>
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Topics</InputLabel>
                  <Select
                    name="topics"
                    multiple
                    value={formData.topics}
                    onChange={handleTopicsChange}
                    renderValue={(selected) =>
                      topics
                        .filter((topic) => selected.includes(topic.id))
                        .map((topic) => topic.name)
                        .join(", ")
                    }
                  >
                    {topics.map((topic) => (
                      <MenuItem key={topic.id} value={topic.id}>
                        <Checkbox
                          checked={formData.topics.includes(topic.id)}
                        />
                        <ListItemText primary={topic.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenDeleteModal}
                >
                  Delete User
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Are you sure?
          </Typography>
          <Typography>
            This action will permanently delete {formData.name}'s user.
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default EditUser;
