import React from "react";
import Image1 from "../assets/1.png";
import Image2 from "../assets/2.png";
import Image3 from "../assets/3.png";
import Image4 from "../assets/4.png";
import Image5 from "../assets/5.png";
import BackgroundImage1 from "../assets/Background.png";
import "../styles/Home.css";
import { Carousel } from "antd";
import { Typography } from "@mui/material";
import useScreenSize from "../components/UseScreenSize";
import { Button, styled } from "@mui/material";
import { ArrowRightAltRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const CosmosButton = styled(Button)({
  backgroundColor: "#afa5d6",
  color: "#151338",
  fontFamily: "'Lora', serif",
  "&:hover": {
    backgroundColor: "#622d6c",
    color: "#e4e4e4",
  },
  borderRadius: "20px",
  padding: "0.6rem 1rem",
  fontSize: "1.2rem",
  // fontWeight: "bold",
  transition: "background-color 0.3s ease-in-out",
  position: "relative",
  overflow: "hidden",
});

const CosmosOverlay = styled("div")({
  backgroundColor: "#afa5d6",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  // backgroundImage: "url(" + Cosmos + ")",
  backgroundSize: "cover",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
});
const images = [Image1, Image2, Image3, Image4, Image5];
function App() {
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const contentStyle = {
    width:
      screenSize.width >= 1000 ? screenSize.width / 4 : screenSize.width / 2,
    textAlign: "center",

    marginInline: "auto",
    border: "none",
    borderRadius: "1.4rem",
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundImage: "url(" + BackgroundImage1 + ")",
        backgroundSize: "cover",
      }}
      classname="home"
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Poppins:wght@400;600&display=swap"
        rel="stylesheet"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Aclonica&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=PT+Mono&display=swap"
        rel="stylesheet"
      />
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: screenSize.width >= 1200 ? "column" : "column",
          justifyContent: "space-between",
          gap: "1rem",
          alignItems: "center",
          width: "80%",
          marginInline: "auto",
          // marginTop: "auto",
          marginBottom: "1rem",
        }}
      >
        <Carousel
          adaptiveHeight
          autoplay
          autoplaySpeed={2000}
          dots={false}
          style={{
            width:
              screenSize.width >= 1000
                ? screenSize.width / 4
                : screenSize.width / 2,
            marginInline: "auto",
          }}
          effect="fade"
        >
          {images.map((val) => {
            return (
              <div>
                <img alt={val} style={contentStyle} src={val}></img>
              </div>
            );
          })}
        </Carousel>
        <span className="typography-heading">
          Hi! I'm <span className="name-wrapper">Geetaa Sawant.</span>
        </span>
        <Typography className="typography-text">
          I provide vast and astrology consultations, including numerology for
          those without birth times, and offers solutions for prosperity and
          happiness. I guide individuals toward mental stability through
          "Saptachakra Shudhhi Kriya."
        </Typography>
        <CosmosButton
          onClick={() => {
            navigate("/auth");
          }}
        >
          Login <ArrowRightAltRounded />
          <CosmosOverlay />
        </CosmosButton>
      </div>
    </div>
  );
}

export default App;
