import CryptoJS from "crypto-js";

const secretKey =
  "NmdaVbJgZHguLhXYA1AWawPHiJ3hnPIqUo1zRIfm9CKPAaboSIsbXd6hTI32bIrVJrC9nsxZR6UnazEkLQUkCyGqmH7ajwFHzKsV6iALlWONU3WG0wmiNkBz0urK8GLL";

export const encrypt = (plainText) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, secretKey).toString();
  return cipherText;
};

export const decrypt = (cipherText) => {
  if (cipherText === null) {
    return false;
  }
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};
