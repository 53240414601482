import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import NightSky from "../assets/night_sky.jpg";
// import MilkyWay from "../assets/milky_way.jpg";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  ArrowBack,
  // Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { db } from "../../config/firebase";
import { collection, getDocs, where, query } from "@firebase/firestore";
import { encrypt } from "../../services/encrypt";
import { useNavigate } from "react-router-dom";
// import Starfield from "../components/StarrySky";
import StarrySky from "../../components/StarrySky";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://geetaasawant.in">
        Geetaa Sawant
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AdminLogin() {
  const [bannerOpen, setBannerOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const handleClickShowPassword = () => setVisible(!visible);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (
      data.get("username").length === 0 ||
      data.get("password").length === 0
    ) {
      setBannerOpen(true);
      setError("Please fill all the required inputs");
      setIsLoading(false);
    } else {
      const q = query(
        collection(db, "administrators"),
        where("username", "==", data.get("username")),
        where("password", "==", data.get("password")),
      );

      await getDocs(q).then((value) => {
        if (value.docs.length === 0) {
          setBannerOpen(true);
          setError("Incorrect Username or Password.");
          setIsLoading(false);
        } else {
          setBannerOpen(false);
          setIsLoading(false);
          var data = encrypt(JSON.stringify(value.docs[0].data()));
          sessionStorage.setItem("adminToken", data);
          navigate("/admin/");
        }
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            // backgroundImage: "url(" + MilkyWay + ")",
            backgroundImage:
              "linear-gradient(to right top, #091023, #131d36, #1e284a, #2b355f, #394174, #3a4277, #3c427a, #3d437d, #30396e, #232e60, #162452, #081b44)",
            backgroundPosition: "left",
          }}
        >
          <StarrySky
            speedFactor={0.25}
            // backgroundColor="#26212f"
            starColor={[254, 166, 204]}
            starCount={1000}
            starSizeFactor={2}
            minStarSize={3}
            maxStarSize={5}
            // debugFPS={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          // component={Paper}
          // elevation={10}
          square
          style={{
            zIndex: 3,
            backgroundColor: "#c5fcee",
            opacity: 1,
          }}
        >
          <IconButton
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Login
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Alert
                style={{ display: bannerOpen ? "" : "none" }}
                severity="error"
              >
                {error}
              </Alert>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={visible ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {visible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                disabled={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {isLoading ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Admin Log In"
                )}
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
