import { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Checkbox,
  ListItemText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { query, collection, getDocs, addDoc, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";

// Helper function to auto-generate password
const generatePassword = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
  let password = "";
  for (let i = 0; i < 12; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};

export default function CreateUser() {
  const password = generatePassword();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    username: "",
    password: password,
    confirmPassword: password,
    topics: [],
  });

  const [errors, setErrors] = useState({});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [topics, setTopics] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getData = async () => {
    var documentArray = [];
    const q = query(collection(db, "topics"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      documentArray.push({ id: doc.id, name: doc.data().name });
    });
    setTopics(documentArray);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleGeneratePassword = () => {
    const password = generatePassword();
    setFormData({
      ...formData,
      password: password,
      confirmPassword: password,
    });
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const checkDuplicateUser = async (field, value) => {
    const q = query(collection(db, "users"), where(field, "==", value));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Basic validation checks
    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.username) validationErrors.username = "Username is required";
    if (!formData.password) validationErrors.password = "Password is required";
    if (!formData.confirmPassword)
      validationErrors.confirmPassword = "Confirm Password is required";
    if (formData.password !== formData.confirmPassword)
      validationErrors.confirmPassword = "Passwords do not match";
    if (formData.topics.length === 0)
      validationErrors.topics = "Select at least one topic";

    // Check for duplicate email and username
    const isDuplicateEmail = await checkDuplicateUser("email", formData.email);
    const isDuplicateUsername = await checkDuplicateUser(
      "username",
      formData.username
    );

    if (isDuplicateEmail) validationErrors.email = "Email already exists";
    if (isDuplicateUsername)
      validationErrors.username = "Username already exists";

    setErrors(validationErrors);

    // If no errors, handle form submission
    if (Object.keys(validationErrors).length === 0) {
      try {
        // Add document to the Firebase 'users' collection
        await addDoc(collection(db, "users"), {
          name: formData.name,
          email: formData.email,
          username: formData.username,
          password: formData.password, // You may want to hash this
          topics: formData.topics,
        });

        alert("User created succesfully");
        // Reset form after successful submission
        const password = generatePassword();
        setFormData({
          name: "",
          email: "",
          username: "",
          password: password,
          confirmPassword: password,
          topics: [],
        });
        setErrors({});
      } catch (error) {
        alert("Error adding user: ", error);
      }
    }
  };

  return (
    <Grid container spacing={2} sx={{ maxWidth: 600, margin: "auto" }}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          error={!!errors.username}
          helperText={errors.username}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <Button onClick={handleGeneratePassword}>Generate</Button>
            ),
          }}
          error={!!errors.password}
          helperText={errors.password}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          value={formData.confirmPassword}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.topics}>
          <InputLabel
            sx={{
              top: "0px",
              left: 0,
              "&.MuiInputLabel-shrink": {
                top: "-8px", // Adjust as needed
                left: 0,
              },
            }}
          >
            Topics
          </InputLabel>
          <Select
            disabled={topics.length === 0}
            name="topics"
            multiple
            value={formData.topics}
            onChange={(e) => {
              const { value } = e.target;
              setFormData({
                ...formData,
                topics: typeof value === "string" ? value.split(",") : value,
              });
            }}
            renderValue={(selected) => {
              const selectedTopics = topics.filter((topic) =>
                selected.includes(topic.id)
              );
              return selectedTopics.map((topic) => topic.name).join(", ");
            }}
          >
            {topics.map((topic) => (
              <MenuItem key={topic.id} value={topic.id}>
                <Checkbox checked={formData.topics.includes(topic.id)} />
                <ListItemText primary={topic.name} />
              </MenuItem>
            ))}
          </Select>
          {errors.topics && (
            <Typography variant="body2" color="error">
              {errors.topics}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
