import React, { useEffect } from "react";

export default function Starfield(props) {
  const {
    speedFactor = 0.2,
    backgroundColor = "#070221",
    starColor = [254, 166, 204],
    starCount = 5000,
    starSizeFactor = 1,
    minStarSize = 2,
    maxStarSize = 3,
    debugFPS = false, // New prop to enable FPS debug
  } = props;

  useEffect(() => {
    const canvas = document.getElementById("starfield");
    const fpsElement = document.getElementById("fps");

    if (canvas) {
      const c = canvas.getContext("2d");

      if (c) {
        let w = window.innerWidth;
        let h = window.innerHeight;

        const setCanvasExtents = () => {
          canvas.width = w;
          canvas.height = h;
        };

        setCanvasExtents();

        window.onresize = () => {
          setCanvasExtents();
        };

        const makeStars = (count) => {
          const out = [];
          for (let i = 0; i < count; i++) {
            const s = {
              x: Math.random() * 1600 - 800,
              y: Math.random() * 900 - 450,
              z: Math.random() * 1000,
            };
            out.push(s);
          }
          return out;
        };

        let stars = makeStars(starCount);

        const clear = () => {
          c.fillStyle = backgroundColor;
          c.fillRect(0, 0, canvas.width, canvas.height);
        };

        const putPixel = (x, y, brightness, size) => {
          const rgb =
            "rgba(" +
            starColor[0] +
            "," +
            starColor[1] +
            "," +
            starColor[2] +
            "," +
            brightness +
            ")";
          c.fillStyle = rgb;
          c.fillRect(x, y, size, size);
        };

        const moveStars = (distance) => {
          const count = stars.length;
          for (var i = 0; i < count; i++) {
            const s = stars[i];
            s.z -= distance;
            while (s.z <= 1) {
              s.z += 1000;
            }
          }
        };

        let prevTime;
        let frameCount = 0;
        let fps = 0;
        let fpsTime = 0;

        const init = (time) => {
          prevTime = time;
          requestAnimationFrame(tick);
        };

        const tick = (time) => {
          let elapsed = time - prevTime;
          prevTime = time;

          moveStars(elapsed * speedFactor);

          clear();

          const cx = w / 2;
          const cy = h / 2;

          const count = stars.length;
          for (var i = 0; i < count; i++) {
            const star = stars[i];

            const x = cx + star.x / (star.z * 0.001);
            const y = cy + star.y / (star.z * 0.001);

            if (x < 0 || x >= w || y < 0 || y >= h) {
              continue;
            }

            const d = star.z / 1000.0;
            const b = 1 - d * d;

            const size = Math.max(
              minStarSize,
              Math.min(maxStarSize, (starSizeFactor / star.z) * 100),
            );

            putPixel(x, y, b, size);
          }

          // FPS calculation
          frameCount++;
          fpsTime += elapsed;

          if (fpsTime >= 1000) {
            fps = frameCount;
            frameCount = 0;
            fpsTime = 0;
            if (fpsElement && debugFPS) {
              fpsElement.innerText = `FPS: ${fps}`;
            }
          }

          requestAnimationFrame(tick);
        };

        requestAnimationFrame(init);

        window.addEventListener("resize", function () {
          w = window.innerWidth;
          h = window.innerHeight;
          setCanvasExtents();
        });
      } else {
        console.error("Could not get 2d context from canvas element");
      }
    } else {
      console.error('Could not find canvas element with id "starfield"');
    }

    return () => {
      window.onresize = null;
    };
  }, [
    starColor,
    backgroundColor,
    speedFactor,
    starCount,
    starSizeFactor,
    minStarSize,
    maxStarSize,
    debugFPS,
  ]);

  return (
    <>
      <canvas
        id="starfield"
        style={{
          padding: 0,
          margin: 0,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          opacity: 1,
          pointerEvents: "none",
          mixBlendMode: "screen",
        }}
      ></canvas>
      {debugFPS && (
        <div
          id="fps"
          style={{
            position: "fixed",
            top: 10,
            left: 10,
            color: "white",
            fontSize: "16px",
            zIndex: 20,
          }}
        >
          FPS: 0
        </div>
      )}
    </>
  );
}
