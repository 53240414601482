import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";
export default function Logout() {
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  useEffect(handleLogout);
  return (
    <div
      style={{ marginInline: "auto", marginTop: "1rem", textAlign: "center" }}
    >
      <Typography>
        Please wait, We are rediricting you to the home page
      </Typography>
    </div>
  );
}
