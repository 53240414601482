import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { decrypt } from "../../services/encrypt";
import {
  CreateNewFolderRounded,
  Edit,
  Logout,
  Person,
  SaveAsRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CreateUser from "./user/CreateUser";
import UserList from "./user/UserList";
import CreateTopic from "./topic/CreateTopic";
import TopicList from "./topic/TopicList";
const drawerWidth = 240;

function AdminDashboard(props) {
  const navigate = useNavigate();
  const [token, setToken] = React.useState(
    decrypt(sessionStorage.getItem("adminToken"))
  );

  const handleLogin = () => {
    if (token) {
      var jsonToken = JSON.parse(token);
      if ("username" in jsonToken && "password" in jsonToken) {
      } else {
        sessionStorage.clear();
        navigate("/admin/login");
      }
    } else {
      navigate("/admin/login");
    }
  };
  React.useEffect(handleLogin);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const logout = [{ name: "Logout", path: "/logout", icon: <Logout /> }];
  const user = [
    { name: "Create User", icon: <Person />, element: <CreateUser /> },
    { name: "Edit User", icon: <SaveAsRounded />, element: <UserList /> },
  ];

  const topics = [
    {
      name: "Create Topic",
      icon: <CreateNewFolderRounded />,
      element: <CreateTopic />,
    },
    { name: "Edit Topic", icon: <Edit />, element: <TopicList /> },
  ];

  const [element, setElement] = React.useState();
  const [title, setTitle] = React.useState("Welcome to Admin Portal");

  const drawer = (
    <div>
      <Toolbar>
        <Typography style={{ marginInline: "auto" }} variant="button">
          Web-Admin
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {user.map((item, index) => (
          <>
            <ListItemButton
              onClick={() => {
                setElement(item.element);
                setTitle(item.name);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </>
        ))}
      </List>
      <Divider />
      <List>
        {topics.map((item, index) => (
          <>
            <ListItemButton
              onClick={() => {
                setElement(item.element);
                setTitle(item.name);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </>
        ))}
      </List>
      <Divider />

      <List>
        {logout.map((item, index) => (
          <ListItem
            onClick={() => {
              navigate(item.path);
            }}
            key={item.path}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {element}
      </Box>
    </Box>
  );
}

export default AdminDashboard;
