import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled/macro";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { db } from "../config/firebase";
import { collection, getDocs, where, query } from "@firebase/firestore";
import { encrypt } from "../services/encrypt";
import { useNavigate } from "react-router-dom";
import StarrySky from "../components/StarrySky";
const CosmosButton = styled(Button)({
  backgroundColor: "#afa5d6",
  color: "#151338",
  fontFamily: "'Lora', serif",
  "&:hover": {
    backgroundColor: "#622d6c",
    color: "#e4e4e4",
  },
  borderRadius: "2rem",
  padding: "0.2rem 2rem",
  fontSize: "1.2rem",
  // fontWeight: "bold",
  transition: "background-color 0.3s ease-in-out",
  position: "relative",
  overflow: "hidden",
});

const CosmosOverlay = styled("div")({
  backgroundColor: "#afa5d6",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  // backgroundImage: "url(" + Cosmos + ")",
  backgroundSize: "cover",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
});
function Copyright(props) {
  return (
    <Typography
      style={{ color: "#1e1e1e" }}
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://geetaasawant.in">
        Geetaa Sawant
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const [bannerOpen, setBannerOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const handleClickShowPassword = () => setVisible(!visible);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (
      data.get("username").length === 0 ||
      data.get("password").length === 0
    ) {
      setBannerOpen(true);
      setError("Please fill all the required inputs");
      setIsLoading(false);
    } else {
      const q = query(
        collection(db, "users"),
        where("username", "==", data.get("username")),
        where("password", "==", data.get("password"))
      );

      await getDocs(q).then((value) => {
        if (value.docs.length === 0) {
          setBannerOpen(true);
          setError("Incorrect Username or Password.");
          setIsLoading(false);
        } else {
          setBannerOpen(false);
          setIsLoading(false);
          var data = encrypt(JSON.stringify(value.docs[0].data()));
          sessionStorage.setItem("token", data);
          navigate("/auth");
        }
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      {/* Starry sky background outside the grid */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1, // Background stays behind the content
          backgroundImage:
            "linear-gradient(to right top, #091023, #131d36, #1e284a, #2b355f, #394174, #3a427d, #3d437d, #30396e, #232e60, #162452, #081b44)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <StarrySky
          speedFactor={0.25}
          starColor={[254, 166, 204]}
          starCount={1000}
          starSizeFactor={2}
          minStarSize={3}
          maxStarSize={5}
        />
      </Box>

      {/* Sign-in grid and form */}
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          zIndex: 2, // Ensure content is above the background
          position: "relative", // Position relative to the absolute background
        }}
      >
        {/* Back button in the top left corner */}
        <Box position="absolute" top={10} left={10} zIndex={3}>
          <Box
            sx={{
              backgroundColor: "rgb(241, 237, 255,0.5)", // Reduce opacity of the sidebar
              borderRadius: "50%",
              padding: "4px", // Add padding to make the circle
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)", // Optional: Add a subtle shadow for better visibility
            }}
          >
            <IconButton
              onClick={() => {
                navigate("/");
              }}
              sx={{
                color: "#000", // Set icon color (you can change it as needed)
              }}
            >
              <ArrowBack sx={{ color: "#1e1e1e" }} />
            </IconButton>
          </Box>
        </Box>

        {/* Smaller, floating sign-in sidebar */}
        <Grid
          item
          // xs={12}
          // sm={8}
          // md={4}
          sx={{
            zIndex: 3,
            backgroundColor: "rgb(241, 237, 255,0.8)", // Reduce opacity of the sidebar
            height: "60%", // Reduce height to make it smaller and floating
            margin: "auto", // Center it vertically
            borderRadius: "16px", // Add rounded corners to enhance floating effect
            display: "flex",

            flexDirection: "column",
            justifyContent: "space-around", // Center content
            alignItems: "center",
            width: {
              xs: "80%", // 80% width for small screens (mobile)
              sm: "60%", // 60% for tablets
              md: "40%", // 40% for medium-sized screens
              lg: "26%", // 30% for large desktops
            },
            color: "#1e1e1e",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)", // Optional: shadow for floating effect
          }}
        >
          {/* Sign in text at the top */}
          <Box
            sx={{
              mt: 5, // Margin top to push it a bit down
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              // position: "fixed",
              gap: "1rem",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "secondary.main",
                width: "2rem",
                height: "2rem",
              }}
            >
              {" "}
              {/* Increase size */}
              <LockOutlinedIcon sx={{ fontSize: "1.5rem" }} />{" "}
              {/* Increase the icon size */}
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontSize: "1.8rem", // Adjust font size to make it larger
                // width: "80%", // Occupy 80% of the box width
                textAlign: "center", // Center the text horizontally
              }}
            >
              Sign in
            </Typography>
          </Box>

          {/* Centered form with button attached */}
          <Alert
            style={{ display: bannerOpen ? "" : "none", width: "80%" }}
            severity="error"
            variant="outlined"
          >
            {error}
          </Alert>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              maxWidth: 400,
              width: "80%",
              textAlign: "center", // Center-align form content
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              variant="standard"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              variant="standard"
              name="password"
              label="Password"
              type={visible ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CosmosButton
              disabled={isLoading}
              type="submit"
              // fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              {isLoading ? (
                <CircularProgress style={{ color: "#1e1e1e" }} />
              ) : (
                "Sign In"
              )}
              <CosmosOverlay />
            </CosmosButton>
            {/* <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              {isLoading ? (
                <CircularProgress style={{ color: "#1e1e1e" }} />
              ) : (
                "Sign In"
              )}
            </Button> */}
          </Box>

          {/* Copyright at the bottom */}
          <Box sx={{ mb: 5 }}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
