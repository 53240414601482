import { useState } from "react";
import { decrypt } from "../../services/encrypt";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function AdminAuthManager() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(
    decrypt(sessionStorage.getItem("adminToken"))
  );
  const handleLogin = () => {
    if (token) {
      var jsonToken = JSON.parse(token);
      if ("username" in jsonToken && "password" in jsonToken) {
        navigate("/admin/dashboard");
      } else {
        sessionStorage.clear();
        navigate("/admin/login");
      }
    } else {
      navigate("/admin/login");
    }
  };
  useEffect(handleLogin);
  return;
}
