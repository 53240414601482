import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../pages/App";
import AuthManager from "../pages/AuthManager";
import Dashboard from "../pages/Dashboard";
import SignInSide from "../pages/Login";
import Logout from "../pages/Logout";
import AdminAuthManager from "../pages/admin/AdminAuth";
import AdminLogin from "../pages/admin/AdminLogin";
import AdminDashboard from "../pages/admin/AdminDashboard";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/auth" element={<AuthManager />} />
        <Route path="/login" element={<SignInSide />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/admin" element={<AdminAuthManager />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
