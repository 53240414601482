import { useState } from "react";
import { decrypt } from "../services/encrypt";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function AuthManager() {
  const navigate = useNavigate();
  const [token, setToken] = useState(decrypt(sessionStorage.getItem("token")));
  const handleLogin = () => {
    if (token) {
      var jsonToken = JSON.parse(token);
      if (
        "username" in jsonToken &&
        "email" in jsonToken &&
        "password" in jsonToken &&
        "topics" in jsonToken
      ) {
        navigate("/dashboard");
      } else {
        sessionStorage.clear();
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(handleLogin);
  return;
}
