import React from "react";
import { Replay } from "vimond-replay";
import "vimond-replay/index.css";

const VideoPlayer = (props) => {
  return (
    <Replay initialPlaybackProps={{ isPaused: true }} source={props.url} />
  );
};

export default VideoPlayer;
