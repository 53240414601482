import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
  Card,
  CardContent,
  IconButton,
  Modal,
  Grid,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { collection, addDoc } from "@firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { db, storage } from "../../../config/firebase"; // Import storage from the same config file

const CreateTopic = () => {
  const [topicName, setTopicName] = useState("");
  const [files, setFiles] = useState([]);
  const [videoTitles, setVideoTitles] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [thumbnails, setThumbnails] = useState({});

  useEffect(() => {
    const generateThumbnails = () => {
      files.forEach((file, index) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          video.currentTime = 4; // Seek to the 4th second
        };
        video.onseeked = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0);
          setThumbnails((prevThumbnails) => ({
            ...prevThumbnails,
            [index]: canvas.toDataURL("image/jpeg"),
          }));
        };
      });
    };

    if (files.length > 0) {
      generateThumbnails();
    }
  }, [files]);

  const handleTopicChange = (e) => {
    setTopicName(e.target.value);
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleTitleChange = (index, title) => {
    setVideoTitles({
      ...videoTitles,
      [index]: title,
    });
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setVideoTitles((prevTitles) => {
      const newTitles = { ...prevTitles };
      delete newTitles[index];
      return newTitles;
    });
    setThumbnails((prevThumbnails) => {
      const newThumbnails = { ...prevThumbnails };
      delete newThumbnails[index];
      return newThumbnails;
    });
  };

  const handleUpload = async () => {
    if (!topicName.trim()) {
      alert("Topic name is required");
      return;
    }

    const invalidTitles = files.some((_, index) => !videoTitles[index]?.trim());
    if (invalidTitles) {
      alert("All video titles are required");
      return;
    }

    setLoading(true);
    setOpenProgressModal(true);
    setError("");

    const videoUrls = [];

    const uploadPromises = files.map((file, index) => {
      const fileRef = ref(storage, `videos/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prev) => ({
              ...prev,
              [index]: progress,
            }));
          },
          (err) => {
            console.error("Upload error:", err);
            setError("Error uploading videos. Please try again.");
            reject(err);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              videoUrls.push({
                title: videoTitles[index] || file.name,
                url: downloadURL,
              });

              if (videoUrls.length === files.length) {
                await addDoc(collection(db, "topics"), {
                  name: topicName.trim(),
                  videos: videoUrls,
                });
                console.log("Topic successfully added with videos!");
                setTopicName("");
                setFiles([]);
                setVideoTitles({});
                setUploadProgress({});
                setThumbnails({});
                setLoading(false);
                setOpenProgressModal(false);
                resolve();
              }
            } catch (error) {
              console.error("Error getting download URL:", error);
              setError("Error getting video URL. Please try again.");
              reject(error);
            }
          }
        );
      });
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("Error in upload process:", error);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 600,
        mx: "auto",
        p: 3,
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 2,
        my: 4,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Create Topic
      </Typography>
      <TextField
        fullWidth
        label="Topic Name"
        value={topicName}
        onChange={handleTopicChange}
        error={!!error}
        helperText={error}
        sx={{ mb: 2 }}
      />
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          mb: 2,
          p: 2,
          textAlign: "center",
        }}
      >
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Upload Video Files
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <IconButton
              component="label"
              color="primary"
              size="small"
              sx={{
                p: 1,
                mb: 2,
              }}
            >
              <UploadIcon />
              <input
                type="file"
                multiple
                accept="video/*"
                onChange={handleFileChange}
                hidden
              />
            </IconButton>
            {files.length > 0 && (
              <Box sx={{ width: "100%" }}>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 2,
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: 120, sm: 150 }, // Responsive width
                        height: { xs: 90, sm: 120 }, // Responsive height
                        bgcolor: "#f0f0f0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        borderRadius: 1,
                        boxShadow: 1,
                      }}
                    >
                      {thumbnails[index] ? (
                        <img
                          src={thumbnails[index]}
                          alt={file.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Typography variant="body2">Loading...</Typography>
                      )}
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveFile(index)}
                        sx={{ mt: 1 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Typography variant="body1">{file.name}</Typography>
                      <TextField
                        fullWidth
                        label={`Title for ${file.name}`}
                        value={videoTitles[index] || ""}
                        onChange={(e) =>
                          handleTitleChange(index, e.target.value)
                        }
                        sx={{ mb: 1, mt: 1 }}
                        error={!videoTitles[index]}
                        helperText={
                          !videoTitles[index] ? "Title is required" : ""
                        }
                      />
                    </Box>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpload}
                  disabled={loading}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Upload and Create Topic"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      <Modal
        open={openProgressModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "90%",
            maxWidth: 600,
            bgcolor: "background.paper",
            p: 3,
            borderRadius: 1,
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Upload Progress
          </Typography>
          {files.map((file, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="body1">{file.name}</Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress[index] || 0}
                sx={{ mt: 1 }}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {Math.round(uploadProgress[index] || 0)}%
              </Typography>
            </Box>
          ))}
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateTopic;
